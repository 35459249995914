<template>
  <div
    v-if="($auth.user.email && $auth.user.name && $auth.isAuthenticated) || $route.name === 'auth-login'"
    :class="contentWidth === 'boxed' ? 'container p-0' : null"
  >
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    if (!this.$auth.isAuthenticated) {
      this.router.push({
        name: 'auth-login',
      })
    }
    return { contentWidth }
  },
}
</script>
